function imgDrag() {
    $("img").attr("draggable", "false");
}
$(document).ready(function () {
    lazyload();
}),
    imgDrag(),
    $("#mainMenu").on("shown.bs.collapse", function () {
        $(".navbar").addClass("is-open"), $("body").addClass("overflow-hidden");
    }),
    $("#mainMenu").on("hidden.bs.collapse", function () {
        $(".navbar").removeClass("is-open"),
            $("body").removeClass("overflow-hidden");
    }),
    jQuery(".action-link").on("click", function (e) {
        e.preventDefault(),
            $(this).text(
                "Read more" == $(this).text() ? "Read Less" : "Read more"
            ),
            $(this)
                .closest(".content-wrapper")
                .find(".hidden-content")
                .slideToggle();
    }),
    $(".testimonial-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !1,
        speed: 1e3,
        autoplay: !0,
        autoplaySpeed: 5e3,
        dots: !0,
        responsive: [
            {
                breakpoint: 2e3,
                settings: { appendDots: $(".custom-slick-dots") },
            },
            {
                breakpoint: 992,
                settings: { appendDots: $(".custom-slick-dots-sm") },
            },
        ],
    });
